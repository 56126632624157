@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Ubuntu", sans-serif;
}

body {
  margin: 0;
  background-color: #291d43;
  color: white;
}

.font-dancing {
  font-family: "Dancing Script", cursive !important;
}